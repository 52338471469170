import { graphql } from 'gatsby'
import { ILegal } from '../components/shared/contentfu.interface'
import { PageContext } from '../pageContext'
import React from 'react'
import Seo from '../components/Seo'
import SiteLayout from '../components/layout/layout'

type Props = {
    data: {
      allContentfulPrivacyPage: {
        nodes: ILegal[]
      };
    };
    pageContext: PageContext;
  }
const Legal: React.FC<Props> = ({data, pageContext}: Props) => {

    return  (
        <SiteLayout locale={pageContext.locale} componentName={pageContext.componentName}>
        <Seo
          title={data.allContentfulPrivacyPage.nodes[0].title}
          description="Privatcoronatest - Privatpolitik. Vi passer på dine data i henhold til alle gældende love"
          keywords={["privatpolitik", "Privat Corona Test" ]}
          pathname="company"
          lang={pageContext.locale}
        />
          <div style={{margin: '3rem 0'}}
            dangerouslySetInnerHTML={{
              __html: data.allContentfulPrivacyPage.nodes[0].policy.childContentfulRichText.html
            }}
            />
      </SiteLayout>

    )
    
}

export default Legal

export const query = graphql`
  query PrivacyQuery($locale: String) {
    allContentfulPrivacyPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        policy {
          childContentfulRichText {
            html
          }
        }
        title
      }
    }
  }
`;
